.card {
    margin-bottom: 132px;
    &__heading {
        padding-bottom: 15px;
        margin-bottom: 55px;
        border-bottom: 1px solid rgb(var(--fireEngineRed));
    }
    &__head {
        margin-bottom: 48px;
        &-gallery {
            flex-shrink: 0;
            width: 100%;
            max-width: 394px;
            margin-right: 16px;
        }
    }
    &__name {
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: 900;
        color: rgb(var(--charCoal));
    }
    &__dscr {
        p {
            margin-bottom: 22px;
            font-size: 16px;
            line-height: 22px;
        }
    }
    &__content {
        margin-bottom: 70px;
        &-column {
            width: 50%;
        }
        &-row {
            &:not(:last-child) {
                margin-bottom: 52px;
            }
        }
    }
    &__tables {
        overflow-x: auto;
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }
}

@media (max-width: 992px) {
    .card {
        margin-bottom: 90px;
        &__head {
            &-gallery {
                max-width: 288px;
            }
        }
    }
}

@media (max-width: 767px) {
    .card {
        margin-bottom: 60px;
        &__head {
            flex-direction: column;
            align-items: center;
            &-gallery {
                max-width: 394px;
                margin-right: 0;
                margin-bottom: 30px;
            }
        }
        &__content {
            margin-bottom: 30px;
            &-column {
                width: 100%;
            }
            &-row {
                &:not(:last-child) {
                    margin-bottom: 30px;
                }
            }
        }
    }
}