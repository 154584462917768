.simpleTable {
    flex-shrink: 0;
    border: 1px solid rgb(var(--sunDown));
    &:not(.small) {
        width: 394px;
    }
    &.small {
        width: 188px;
    }
    &:not(:last-child) {
        margin-right: 18px;
    }
    &__head,
    &__body {
        padding-right: 11px;
        padding-left: 11px;
    }
    &__head {
        margin-right: -1px;
        margin-left: -1px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: rgb(var(--nightRider));
        &:not(.grey) {
            background-color: rgb(var(--sunDown));
        }
        &.grey {
            background-color: rgb(var(--gainsboro));
        }
    }
    &__body {
        padding-top: 12px;
        padding-bottom: 12px;
        p {
            font-size: 14px;
            line-height: 20px;
        }
    }
}