.cardGallery {
    &__fullsize,
    &__thumbnails {
        &-item {
            background-color: #fff;
        }
        img {
            display: inline-block;
            vertical-align: middle;
            text-align: center;
        }
    }
    &__fullsize {
        margin-bottom: 20px;
        border: 1px solid rgb(var(--gainsboro));
        &-item {
            height: 394px;
            line-height: 394px;
        }
    }
    &__thumbnails {
        max-width: 288px;
        margin: 0 auto;
        &-item {
            height: 84px;
            line-height: 84px;
            border: 1px solid rgb(var(--gainsboro));
        }
        .slick {
            &-list {
                margin: 0 -10px;
            }
            &-slide {
                margin-right: 10px;
                margin-left: 10px;
            }
        }
    }
}

@media (max-width: 992px) {
    .cardGallery {
        &__fullsize {
            &-item {
                height: 288px;
                line-height: 288px;
            }
        }
        &__thumbnails {
            .slick {
                &-prev,
                &-next {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .cardGallery {
        &__thumbnails {
            .slick {
                &-prev,
                &-next {
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .cardGallery {
        &__thumbnails {
            .slick {
                &-prev,
                &-next {
                    display: none;
                }
            }
        }
    }
}