.extendTable {
    width: 100%;
    min-width: 992px;
    &__row {
        width: 100%;
        margin-right: -2px;
        margin-left: -2px;
        &.head {
            margin-bottom: 4px;
            .extendTable__cell {
                text-align: center;
                color: #fff;
                &-inner {
                    background-color: rgb(var(--fireEngineRed));
                }
                b {
                    font-weight: 800;
                }
            }
        }
        &:not(.head) {
            .extendTable__cell {
                &-inner {
                    border-bottom: 1px solid rgb(var(--gainsboro));
                }
                &:not(:first-child) {
                    text-align: center;
                }
            }
        }
    }
    &__cell {
        padding-right: 2px;
        padding-left: 2px;
        font-size: 14px;
        &:first-child {
            flex-shrink: 0;
            width: 199px;
        }
        &:not(:first-child) {
            flex: 1;
        }
        &-inner {
            height: 100%;
            padding: 8px 12px;
        }
    }
}