.cardTabs {
    &__head {
        margin-bottom: 40px;
        border-bottom: 1px solid rgb(var(--fireEngineRed));
    }
    &__btn {
        position: relative;
        z-index: 1;
        padding: 8px 28px;
        text-transform: uppercase;
        background-color: rgb(var(--tutu));
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 6px;
            z-index: -1;
            width: 100%;
            height: 100%;
            transform: skew(15deg);
            background-color: transparent;
        }
        &:not(.active) {
            color: rgb(var(--dimGray));
        }
        &.active {
            z-index: 2;
            color: #fff;
            background-color: rgb(var(--fireEngineRed));
            &::before {
                background-color: inherit;
            }
        }
    }
    &__tab {
        &:not(.active) {
            display: none;
        }
        &.active {
            display: block;
        }
    }
}